@use '../mixins' as *;
@use '../variables' as *;

.form-input {
  &:not(:last-child) {
    @include indent-outer-bottom(16);
  }
}

.form-input--with-filled-icon {
  svg {
    fill: $color-green;
  }
}
