@use "src/styles/variables" as *;
@use "src/styles/mixins" as *;
@use "src/styles/placeholders" as *;

.pagintaion-bullet {
  display: inline-block;
  position: relative;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  margin-right: 12px;
  background: #C4C4C4;
  transition: .1s transform, .2s background, .1s left;
}

.pagintaion-bullet_active {
  background: $color-green;
  &-prev, &-next {
    transform: scale(.6);
  }
  &-prev-prev, &-next-next {
    transform: scale(.3);
  }
}
