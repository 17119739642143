@use "src/styles/variables" as *;
@use "src/styles/mixins" as *;
@use "src/styles/placeholders" as *;

.registration-link {
  @extend %menu-link;
  @include indent-outer-top(24);
  font-size: 12px;
  line-height: 20px;
  @include screen-small-up {
    @include indent-outer-top(0);
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
  }
}

.form {
  grid-column: content;
  @include indent-outer-top(36);
}

.form__bottom {
  display: flex;
  flex-direction: column;
  @include screen-small-up {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.form__button {
  width: 100%;
  max-width: 220px;
  @include indent-outer-top(24);
  @include screen-small-up {
    @include indent-outer-top(0);
  }
}

.form__button--small {
  max-width: 153px;
}

.form__left {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include screen-small-up {
    flex-direction: column;
    align-items: flex-start;
  }
}

.forgot-password-link {
  @extend %menu-link;
  font-size: 12px;
  line-height: 20px;
  @include indent-outer-top(4);
  @include screen-small-up {
    font-size: 14px;
    line-height: 24px;
  }
  margin-left: 28px;
}

.form__divide-text {
  @include indent-bottom(16);
  font-size: 10px;
  line-height: 16px;
  color: $color-gray-deep;
}
