@use "src/styles/variables" as *;
@use "src/styles/mixins" as *;
@use "src/styles/placeholders" as *;

.static-wrapper {
  @extend %grid-container;
  @include grid-tiny-only([content] 12);
  @include grid-small-up(2 [content] 8 2);
  @include grid-medium-up(2 [content] 8 2);
  @include grid-big-up(2 [content] 8 2);
  @include grid-large-up(2 [content] 8 1);

  > h2 {
    grid-column: content;
    @include indent-bottom(32);
  }

  > p {
    font-size: 18px;
    grid-column: content;
    @include indent-bottom(16);
  }

  > ul {
    grid-column: content;
    @include indent-left(40);
    @include indent-bottom(32);

    ul {
      @include indent-left(40);

      li {
        position: relative;
        padding-left: 16px;
        &:before {
          position: absolute;
          left: 0;
          top: 8px;
          content: '';
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: $color-green;
        }
        &:first-child {
          @include indent-outer-top(20);
        }

        &:not(:last-child) {
          @include indent-bottom(20);
        }
      }
    }

    > li {
      position: relative;
      padding-left: 16px;

      &:before {
        position: absolute;
        left: 0;
        top: 8px;
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: $color-green;
      }

      &:not(:last-child) {
        @include indent-bottom(20);
      }
    }
  }

  > ol {
    counter-reset: ol-counter;
    grid-column: content;
    @include indent-left(40);
    @include indent-bottom(32);

    ol {
      counter-reset: ol-counter-2;
      @include indent-left(40);
      > li {
          &:not(:last-child) {
            @include indent-bottom(20);
          }

          &:first-child {
            @include indent-outer-top(20);
          }

          &:before {
            counter-increment: ol-counter-2;
            content: attr(data-counter) counter(ol-counter) '.' counter(ol-counter-2) '.' ;
            font-weight: 700;
            margin-right: 8px;
          }
        }
    }

    > li {

      &:before {
        counter-increment: ol-counter;
        content: attr(data-counter) counter(ol-counter) '.' ;
        font-weight: 700;
        margin-right: 8px;
      }


      &:not(:last-child) {
        @include indent-bottom(20);
      }
    }
  }
}
