////
/// A set of mixins to simplify the creation of media queries rules
/// according to the design system.
/// @access public
/// @group layout
/// @author Daniil Filippov <d.filippov@make.st>
////

@use "sass:math";
@use "../variables/layout";


/// A media query for selecting only screens with viewport width < 360px.
@mixin tiny-only {
  @media (max-width: layout.$grid-container-tiny-max) {
    @content;
  }
}

/// A media query for selecting screens with viewport width ≥ 360px.
@mixin small-up {
  @media (min-width: layout.$grid-container-small-min) {
    @content;
  }
}

/// A media query for selecting screens with viewport width < 736px
@mixin small-down {
  @media (max-width: layout.$grid-container-small-max) {
    @content;
  }
}

/// A media query for selecting screens with viewport width in
/// [360px, 736px).
@mixin small-only {
  @media (min-width: layout.$grid-container-small-min) and (max-width: layout.$grid-container-small-max) {
    @content;
  }
}

/// A media query for selecting screens with viewport width ≥ 736px.
@mixin medium-up {
  @media (min-width: layout.$grid-container-medium-min) {
    @content;
  }
}

/// A media query for selecting screens with viewport width < 1088px.
@mixin medium-down {
  @media (max-width: layout.$grid-container-medium-max) {
    @content;
  }
}

/// A media query for selecting screens with viewport width in
/// [736px, 1088px).
@mixin medium-only {
  @media (min-width: layout.$grid-container-medium-min) and (max-width: layout.$grid-container-medium-max) {
    @content;
  }
}

/// A media query for selecting screens with viewport width ≥ 736px.
@mixin big-up {
  @media (min-width: layout.$grid-container-big-min) {
    @content;
  }
}

/// A media query for selecting screens with viewport width < 1088px.
@mixin big-down {
  @media (max-width: layout.$grid-container-big-max) {
    @content;
  }
}

/// A media query for selecting screens with viewport width in
/// [736px, 1088px).
@mixin big-only {
  @media (min-width: layout.$grid-container-big-min) and (max-width: layout.$grid-container-big-max) {
    @content;
  }
}

/// A media query for selecting screens with viewport width ≥ 1088px.
@mixin large-up {
  @media (min-width: layout.$grid-container-large-min) {
    @content;
  }
}

/// A media query for selecting screens with viewport width from the given
/// value.
/// @param {length} $min - viewport minimum width (included).
@mixin min($min) {
  @media (min-width: $min) {
    @content;
  }
}

/// A media query for selecting screens with viewport width up to the given
/// value.
/// @param {length} $max - viewport maximum width (not included).
@mixin max($max) {
  @media (max-width: $max) {
    @content;
  }
}

/// A media query for selecting screens with viewport width in the given
/// interval.
/// @param {Length} $min - viewport minimum width (included).
/// @param {length} $max - viewport maximum width (not included).
@mixin interval($min, $max) {
  @media (min-width: $min) and (max-width: $max) {
    @content;
  }
}

/// A media query for selecting screens with viewport width in the first half
/// of the given interval.
/// @param {Length} $min - viewport minimum width (included).
/// @param {length} $max - viewport maximum width (not included).
@mixin former-half-interval($min, $max) {
  $max: $min + round(math.div(($max - $min), 2)) - 1px;
  @include interval ($min, $max) {
    @content;
  }
}

/// A media query for selecting screens with viewport width in the second half
/// of the given interval.
/// @param {Length} $min - viewport minimum width (included).
/// @param {length} $max - viewport maximum width (not included).
@mixin latter-half-interval($min, $max) {
  $min: $min + round(math.div(($max - $min), 2));
  @include interval ($min, $max) {
    @content;
  }
}
