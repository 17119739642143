////
/// A set of mixins to simplify the creation of typography styles.
/// @group typography
/// @author Daniil Filippov <d.filippov@make.st>
/// @link http://standart.gov.design/design/typography
////

@use "sass:list" as list;
@use "../functions/typography" as *;
@use "media-queries" as *;


/// Base mixin for applying font-size and line-height values according to the design system.
/// @param {List} $sizes - A list of `font-size` integer values (in pixels) for the each breakpoint.
/// @param {Boolean} $isHeading [false] - Whether styles are applying to some sort of heading.
@mixin make-sizes($sizes, $isHeading: false) {
  // TODO: Sass currently doesn't support dynamic mixins invocation — define these crutches variables :(
  $tiny: list.nth($sizes, 1) * 1px;
  $small: list.nth($sizes, 2) * 1px;
  $medium: list.nth($sizes, 3) * 1px;
  $big: list.nth($sizes, 4) * 1px;
  $large: list.nth($sizes, 5) * 1px;

  @if ($isHeading) {
    font-size: $tiny;
    line-height: heading-line-height($tiny);

    @if ($small != $tiny) {
      @include small-up {
        font-size: $small;
        line-height: heading-line-height($small);
      }
    }
    @if ($medium != $small) {
      @include medium-up {
        font-size: $medium;
        line-height: heading-line-height($medium);
      }
    }
    @if ($big != $medium) {
      @include big-up {
        font-size: $big;
        line-height: heading-line-height($big);
      }
    }
    @if ($large != $big) {
      @include large-up {
        font-size: $large;
        line-height: heading-line-height($large);
      }
    }
  } @else {
    font-size: $tiny;
    line-height: text-line-height($tiny);

    @if ($small != $tiny) {
      @include small-up {
        font-size: $small;
        line-height: text-line-height($small);
      }
    }
    @if ($medium != $small) {
      @include medium-up {
        font-size: $medium;
        line-height: text-line-height($medium);
      }
    }
    @if ($big != $medium) {
      @include big-up {
        font-size: $big;
        line-height: text-line-height($big);
      }
    }
    @if ($large != $big) {
      @include large-up {
        font-size: $large;
        line-height: text-line-height($large);
      }
    }
  }
}
