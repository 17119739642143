////
/// Placeholders for the common GUI elements
/// @group components
/// @author Daniil Filippov <d.filippov@make.st>
////


@use '../variables' as *;
@use './grid';
@use '../mixins' as *;
@use './typography';

/// A base styles for the custom link's underline.
%link-underline-base {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: currentColor;
  transition: background-color 0.1s linear, opacity 0.1s linear;
  content: "";
  opacity: 0.2;
}

/// A placeholder for the links with an optional active state.
/// The link content must be small in width.
%menu-link {
  position: relative;
  display: inline-block;
  text-decoration: none;
  transition: color 0.1s linear;

  &:before,
  &:after {
    @extend %link-underline-base;
  }

  &:before {
    opacity: 0;
    border-radius: 8px;
    pointer-events: none;
    height: 8px;
    bottom: -12px;

    @include screen-medium-up {
      height: 12px;
      bottom: -20px;
    }

    @include screen-large-up {
      height: 16px;
      bottom: -24px;
    }
  }

  &:after {
    height: 1px;
    opacity: 0.2;
  }

  &:hover {
    //color: $color-azure;

    &:after {
      opacity: 0.4;
    }
  }
}

/// A placeholder for the active state of the %menu-link.
%menu-link-active {
  color: currentColor;

  &:before {
    opacity: 1;
  }

  &:after {
    opacity: 0;
    transition-duration: 0.05s;
  }
}

/// A placeholder for the base link styles.
%link-base {
  display: inline-block;
  position: relative;
  width: fit-content;
  text-decoration: none;
  transition: color 0.1s linear, background-color 0.1s linear, border-color 0.1s linear;
  cursor: pointer;
  width: fit-content;

  //&:focus {
  &:hover {
    color: $color-green-light;
  }
}

%link-manufacturer {
  position: relative;
  display: flex;
  align-items: center;
  width: fit-content;
  &:after {
    position: relative;
    margin-left: 8px;
    width: 10px;
    height: 10px;
    background-image: url("data:image/svg+xml;utf8,<svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M4.75 3L2 3C1.44772 3 1 3.44772 1 4V8C1 8.55228 1.44772 9 2 9H6C6.55228 9 7 8.55228 7 8V5.25' stroke='%23687682'/><path d='M6 1H9V4' stroke='%23687682'/><path d='M9 1L4 6' stroke='%23687682'/></svg>");
    content: "";
    transition: .1s background-image;
  }
  &:hover {
    &:after {
      background-image: url("data:image/svg+xml;utf8,<svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M4.75 3L2 3C1.44772 3 1 3.44772 1 4V8C1 8.55228 1.44772 9 2 9H6C6.55228 9 7 8.55228 7 8V5.25' stroke='%232daa76'/><path d='M6 1H9V4' stroke='%232daa76'/><path d='M9 1L4 6' stroke='%232daa76'/></svg>");
    }
  }
  &:active {
    &:after {
      background-image: url("data:image/svg+xml;utf8,<svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M4.75 3L2 3C1.44772 3 1 3.44772 1 4V8C1 8.55228 1.44772 9 2 9H6C6.55228 9 7 8.55228 7 8V5.25' stroke='%2324bf7e'/><path d='M6 1H9V4' stroke='%2324bf7e'/><path d='M9 1L4 6' stroke='%2324bf7e'/></svg>");
    }
  }
}

/// A placeholder for the base multiline links.
%link-multiline-base {
  display: inline;
  text-decoration: none;
  transition: color 0.1s linear, border-color 0.1s linear;
  border-bottom: 1px solid rgba($color-green, 0.2);

  &:hover, &:focus {
    color: $color-green-light;
    border-bottom-color: rgba($color-green-light, 0.2);
  }
}

/// A placeholder for the links with dashed underlines.
%dashed-underline-link {
  @extend %link-base;
  border-bottom: 1px dashed rgba(#000000, 0.2);
}

/// A placeholder for the links with sparse dashed underlines.
// TODO: fix an issue with "The target selector was not found" sass error
// %sparse-dashed-underline-link {
//   @extend %dashed-underline-link;
//   @extend %label-text;
// }
%sparse-dashed-underline-link {
  @extend %link-base;
  @extend %label-text;

  &:after {
    @extend %sparse-dashed-underline-pattern-mask;
  }
}

%scrollbar {
  &::-webkit-scrollbar {
    width: 3px;
    background: $color-white-half;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    width: 100px;
    background: $color-green;
    border-radius: 4px;
  }
  scrollbar-width: thin;
  scrollbar-color: $color-green $color-white-half;
}
