////
/// Mixins for simplifying the usage of the indent system
/// @access public
/// @group layout
/// @author Daniil Filippov <d.filippov@make.st>
/// @link http://standart.gov.design/design/layout
////


@use 'sass:math';
@use 'sass:map';
@use './media-queries' as screen;
@use '../variables' as *;
@use '../functions/strip-unit' as *;

/// Base mixin for the indent system adaptation.
/// @access private
/// @param {Number|Length} $value - The desktop indent value (unitless or in pixels).
/// Must be specified as the key of the [$indents-mobile](#variable-indents-mobile) map.
/// @param {List} $properties-list - The list of properties to apply the indent values.
@mixin base($value, $properties-list) {
  $px: if($value > 0, 1px, -1px);
  $indent-value: math.abs(strip-unit($value));

  @each $property in $properties-list {
    #{$property}: (map.get($layout-indents-mobile, $indent-value) or $indent-value) * $px;
  }

  @include screen.medium-up {
    @each $property in $properties-list {
      #{$property}: $indent-value * $px;
    }
  }
}


/// Adds the inner top indent for the element according to the design system.
/// @param {Number|Length} $value - The desktop indent value (unitless or in pixels).
/// Must be specified as the key of the [$indents-mobile](#variable-indents-mobile) map.
@mixin top($value) {
  @include base($value, padding-top);
}
/// Adds the inner right indent for the element according to the design system.
/// @param {Number|Length} $value - The desktop indent value (unitless or in pixels).
/// Must be specified as the key of the [$indents-mobile](#variable-indents-mobile) map.
@mixin right($value) {
  @include base($value, padding-right);
}
/// Adds the inner bottom indent for the element according to the design system.
/// @param {Number|Length} $value - The desktop indent value (unitless or in pixels).
/// Must be specified as the key of the [$indents-mobile](#variable-indents-mobile) map.
@mixin bottom($value) {
  @include base($value, padding-bottom);
}
/// Adds the inner left indent for the element according to the design system.
/// @param {Number|Length} $value - The desktop indent value (unitless or in pixels).
/// Must be specified as the key of the [$indents-mobile](#variable-indents-mobile) map.
@mixin left($value) {
  @include base($value, padding-left);
}
/// Adds the inner top and bottom indents for the element according to the design system.
/// @param {Number|Length} $value - The desktop indent value (unitless or in pixels).
/// Must be specified as the key of the [$indents-mobile](#variable-indents-mobile) map.
@mixin vertical-pair($value) {
  @include base($value, padding-top padding-bottom);
}
/// Adds the inner left and right indents for the element according to the design system.
/// @param {Number|Length} $value - The desktop indent value (unitless or in pixels).
/// Must be specified as the key of the [$indents-mobile](#variable-indents-mobile) map.
@mixin horizontal-pair($value) {
  @include base($value, padding-left padding-right);
}
/// Adds the inner indents for the element according to the design system.
/// @param {Number|Length} $value - The desktop indent value (unitless or in pixels).
/// Must be specified as the key of the [$indents-mobile](#variable-indents-mobile) map.
@mixin all($value) {
  @include base($value, padding);
}


/// Adds the outer top indent for the element according to the design system.
/// @param {Number|Length} $value - The desktop indent value (unitless or in pixels).
/// Must be specified as the key of the [$indents-mobile](#variable-indents-mobile) map.
@mixin outer-top($value) {
  @include base($value, margin-top);
}
/// Adds the outer right indent for the element according to the design system.
/// @param {Number|Length} $value - The desktop indent value (unitless or in pixels).
/// Must be specified as the key of the [$indents-mobile](#variable-indents-mobile) map.
@mixin outer-right($value) {
  @include base($value, margin-right);
}
/// Adds the outer bottom indent for the element according to the design system.
/// @param {Number|Length} $value - The desktop indent value (unitless or in pixels).
/// Must be specified as the key of the [$indents-mobile](#variable-indents-mobile) map.
@mixin outer-bottom($value) {
  @include base($value, margin-bottom);
}
/// Adds the outer left indent for the element according to the design system.
/// @param {Number|Length} $value - The desktop indent value (unitless or in pixels).
/// Must be specified as the key of the [$indents-mobile](#variable-indents-mobile) map.
@mixin outer-left($value) {
  @include base($value, margin-left);
}
/// Adds the outer top and bottom indents for the element according to the design system.
/// @param {Number|Length} $value - The desktop indent value (unitless or in pixels).
/// Must be specified as the key of the [$indents-mobile](#variable-indents-mobile) map.
@mixin outer-vertical-pair($value) {
  @include base($value, margin-top margin-bottom);
}
/// Adds the outer left and right indents for the element according to the design system.
/// @param {Number|Length} $value - The desktop indent value (unitless or in pixels).
/// Must be specified as the key of the [$indents-mobile](#variable-indents-mobile) map.
@mixin outer-horizontal-pair($value) {
  @include base($value, margin-left margin-right);
}
/// Adds the outer indents for the element according to the design system.
/// @param {Number|Length} $value - The desktop indent value (unitless or in pixels).
/// Must be specified as the key of the [$indents-mobile](#variable-indents-mobile) map.
@mixin outer-all($value) {
  @include base($value, margin);
}


/// @alias top
@mixin inner-top($value) {
  @include top($value);
}
/// @alias right
@mixin inner-right($value) {
  @include right($value);
}
/// @alias bottom
@mixin inner-bottom($value) {
  @include bottom($value);
}
/// @alias left
@mixin inner-left($value) {
  @include left($value);
}
/// @alias vertical-pair
@mixin inner-vertical-pair($value) {
  @include vertical-pair($value);
}
/// @alias horizontal-pair
@mixin inner-horizontal-pair($value) {
  @include horizontal-pair($value);
}
