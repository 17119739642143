////
/// A set of mixins to simplify the creation of grid styles.
/// @access public
/// @group grid
/// @author Daniil Filippov <d.filippov@make.st>
////


@use './media-queries' as screen;
@use '../placeholders/grid';

/// A mixin for creating the grid container according to the design system.
@mixin container {
  @extend %grid-container;
  @content;
}

/// A mixin for complex grid template's creation.
/// @param {List} $template - A list containing the grid template (width numbers and columns aliases)
/// @param {Number} $columnsNumber [12] - A maximum possible number of columns in the grid (default 12)
/// @param {Length} $gutter [32px] - A grid gutter width (default 32px)
@mixin template($template, $columnsNumber: 12, $gutter: 32px) {
  $res: ();
  @each $w in $template {
    @if type-of($w) == 'number' and unitless($w) {
      $res: append($res, calc((#{$w * 100%} - #{($columnsNumber - $w) * $gutter}) / #{$columnsNumber}));
    } @else {
      $res: append($res, $w);
    }
  }

  grid-template-columns: $res;
}

/// A mixin for creating "tiny" grid styles (small mobile phones only).
@mixin tiny-only($template, $columnsNumber: 12, $gutter: 8px) {
  @include screen.tiny-only {
    @include template($template, $columnsNumber, $gutter);
    @content;
  }
}

/// A mixin for creating "small" grid styles (mobile phones).
@mixin small-up($template, $columnsNumber: 12, $gutter: 20px) {
  @include screen.small-up {
    @include template($template, $columnsNumber, $gutter);
    @content;
  }
}

/// A mixin for creating "medium" grid styles (tablets).
@mixin medium-up($template, $columnsNumber: 12, $gutter: 24px) {
  @include screen.medium-up {
    @include template($template, $columnsNumber, $gutter);
    @content;
  }
}

/// A mixin for creating "big" grid styles (desktop monitors).
@mixin big-up($template, $columnsNumber: 12, $gutter: 32px) {
  @include screen.big-up {
    @include template($template, $columnsNumber, $gutter);
    @content;
  }
}

/// A mixin for creating "large" grid styles (desktop monitors).
@mixin large-up($template, $columnsNumber: 12, $gutter: 32px) {
  @include screen.large-up {
    @include template($template, $columnsNumber, $gutter);
    @content;
  }
}
