////
/// Global layout variables.
/// @group layout
/// @type Length
/// @author Daniil Filippov <d.filippov@make.st>
////


/// The maximal "tiny" screen size (small mobile phones).
$grid-container-tiny-max: 750px;

/// The minimal "small" screen size (mobile phones).
$grid-container-small-min: $grid-container-tiny-max + 1px;

/// The maximal "small" screen size (mobile phones).
$grid-container-small-max: 1050px;

/// The minimal "medium" screen size (tablets).
$grid-container-medium-min: $grid-container-small-max + 1px;

/// The maximal "medium" screen size (tablets).
/// $grid-container-medium-max: 1087px;
$grid-container-medium-max: 1300px;

/// The minimal "big" screen size (desktop monitors).
$grid-container-big-min: $grid-container-medium-max + 1px;

/// The maximal "big" screen size (desktop monitors).
$grid-container-big-max: 1700px;

/// The minimal "large" screen size (desktop monitors).
$grid-container-large-min: $grid-container-big-max + 1px;

/// The maximal "large" screen size (desktop monitors).
$grid-container-large-max: 1920px;


/// The grid side paddings value for "tiny" screen size.
$grid-padding-tiny: 16px;

/// The grid side paddings value for "small" screen size.
$grid-padding-small: 32px;

/// The grid side paddings value for "medium" screen size.
$grid-padding-medium: 38px;

/// The grid side paddings value for "large" screen size.
$grid-padding-large: 40px;


/// The grid gutter size on "tiny" screen size.
$grid-gutter-tiny: 10px;

/// The grid gutter size on "small" screen size.
$grid-gutter-small: 20px;

/// The grid gutter size on "medium" screen size and up.
$grid-gutter-medium: 24px;

/// The grid gutter size on "big" screen size and up.
$grid-gutter-large: 32px;


/// The top offset for the index page's first projects feed.
/// @todo find better way to set this offset
$first-projects-feed-offset: 135px;

/// The indents values for mobile phones. Keys are indent values
/// for the desktop, values are appropriate indents for mobile phones.
/// @type Map
$indents-mobile: (
  8: 8,
  12: 8,
  16: 12,
  20: 16,
  24: 16,
  28: 20,
  32: 24,
  40: 32,
  48: 32,
  64: 40,
  80: 48,
);
