////
/// Global typography variables.
/// @group typography
/// @type Length
/// @author Daniil Filippov <d.filippov@make.st>
////


/// The design system's base text size on desktop.
$base-text-size-desktop: 14px;

/// The design system's base text size on mobile phones.
$base-text-size-mobile: 12px;

// TODO: write sassdoc for the new typography

$heading-breakpoint1-max: 750px;

$heading-breakpoint2-min: $heading-breakpoint1-max + 1px;
$heading-breakpoint2-max: 1050px;

$heading-breakpoint3-min: $heading-breakpoint2-max + 1px;
$heading-breakpoint3-max: 1300px;

$heading-breakpoint4-min: $heading-breakpoint3-max + 1px;
$heading-breakpoint4-max: 1600px;

$heading-breakpoint5-min: $heading-breakpoint4-max + 1px;

$headings-breakpoints: (
  (0, $heading-breakpoint1-max),
  ($heading-breakpoint2-min, $heading-breakpoint2-max),
  ($heading-breakpoint3-min, $heading-breakpoint3-max),
  ($heading-breakpoint4-min, $heading-breakpoint4-max),
  ($heading-breakpoint5-min, -1),
);

$typo-heading-h1-sizes: (28, 32, 36, 48, 64) !default;
$typo-heading-h2-sizes: (24, 28, 32, 36, 48) !default;
$typo-heading-h3-sizes: (18, 20, 24, 28, 36) !default;
$typo-heading-h4-sizes: (16, 18, 18, 24, 24) !default;

$headings-sizes: (
  h1: $typo-heading-h1-sizes,
  h2: $typo-heading-h2-sizes,
  h3: $typo-heading-h3-sizes,
  h4: $typo-heading-h4-sizes,
);

// TODO: get rid of the legacy typography variables:

/// The h1 heading size on desktop.
$heading-h1-size-large: 64px;
/// The h2 heading size on desktop.
$heading-h2-size-large: 48px;
/// The h3 heading size on desktop.
$heading-h3-size-large: 36px;
/// The h4 heading size on desktop.
$heading-h4-size-large: 24px;

/// The h1 heading size on tablets.
$heading-h1-size-medium: 48px;
/// The h2 heading size on tablets.
$heading-h2-size-medium: 36px;
/// The h3 heading size on tablets.
$heading-h3-size-medium: 28px;
/// The h4 heading size on tablets.
$heading-h4-size-medium: 24px;

/// The h1 heading size on smartphones.
$heading-h1-size-small: 36px;
/// The h2 heading size on smartphones.
$heading-h2-size-small: 32px;
/// The h3 heading size on smartphones.
$heading-h3-size-small: 24px;
/// The h4 heading size on smartphones.
$heading-h4-size-small: 18px;

/// The h1 heading size on small mobile phones.
$heading-h1-size-tiny: 32px;
/// The h2 heading size on small mobile phones.
$heading-h2-size-tiny: 28px;
/// The h3 heading size on small mobile phones.
$heading-h3-size-tiny: 20px;
/// The h4 heading size on small mobile phones.
$heading-h4-size-tiny: 16px;
