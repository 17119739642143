@use "../placeholders" as *;
@use "../variables" as *;

.link {
  @extend %link-base;
}

.link--contrast {
  display: inline-block;
  text-decoration: none;
  transition: opacity 0.1s linear;
  opacity: 0.64;

  &:hover {
    opacity: 1;

    &:before {
      opacity: 0.4;
      background-color: $color-white;
    }
  }
}

.link--manufactured {
  @extend %link-manufacturer;
  gap: 0;
}

.link--dashed {
  display: inline;
  @extend %dashed-underline-link;

  &:hover {
    border-color: rgba($color-green-light, 0.2);
  }
}

.link--disabled {
  pointer-events: none;
  color: $color-gray-dark;
}

.link--underlined {
  position: relative;
  &:before {
    height: 1px;
    @extend %link-underline-base;
  }
}

.link--colored {
  color: $color-green;
}

.link--underline-hover {
  position: relative;

  &:before {
    position: absolute;
    bottom: -5px;
    left: -100vw;
    right: -100vw;
    background-color: $color-green-light;
    height: 1px;
    content: '';
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.25s linear;
    z-index: 2;
  }

  &:hover:before {
    transform: scaleX(1);
  }
}
