@use "./placeholders" as *;

$body-font-family: 'OpenSans', Helvetica, Arial, sans-serif;

@font-face {
  font-family: 'OpenSans';
  font-weight: 400;
  font-display: swap;
  font-style: normal;
  src: url('../fonts/open-sans-400.woff2') format('woff2'),
    url('../fonts/open-sans-400.woff') format('woff'),
    url('../fonts/open-sans-400.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSans';
  font-weight: 300;
  font-display: swap;
  font-style: normal;
  src: url('../fonts/open-sans-300.woff2') format('woff2'),
    url('../fonts/open-sans-300.woff') format('woff'),
    url('../fonts/open-sans-300.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSans';
  font-weight: 600;
  font-display: swap;
  font-style: normal;
  src: url('../fonts/open-sans-600.woff2') format('woff2'),
    url('../fonts/open-sans-600.woff') format('woff'),
    url('../fonts/open-sans-600.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSans';
  font-weight: 700;
  font-display: swap;
  font-style: normal;
  src: url('../fonts/open-sans-700.woff2') format('woff2'),
    url('../fonts/open-sans-700.woff') format('woff'),
    url('../fonts/open-sans-700.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSans';
  font-weight: 800;
  font-display: swap;
  font-style: normal;
  src: url('../fonts/open-sans-800.woff2') format('woff2'),
    url('../fonts/open-sans-800.woff') format('woff'),
    url('../fonts/open-sans-800.ttf') format('truetype');
}

@font-face {
  font-family: 'PTSans';
  font-weight: 400;
  font-display: swap;
  font-style: normal;
  src: url('../fonts/pt-sans-regular.woff2') format('woff2'),
    url('../fonts/pt-sans-regular.woff') format('woff'),
    url('../fonts/pt-sans-regular.ttf') format('truetype');
}

body {
  font-family: $body-font-family;
}

h1 {
  @extend %heading-h1;
  font-weight: 800;
}

h2 {
  @extend %heading-h2;
  font-weight: 600;
}

h3 {
  @extend %heading-h3
}

h4 {
  @extend %heading-h4
}
