////
/// Design system's colors.
/// @group colors
/// @type Color
/// @author Dmitry Monsh <d.monsh@make.st>
////

/// The main accent color. Used for interactive elements
$green: #339069;

/// The light green color. Used for the `:hover` state interactive elements
$green-light: #2DAA76;

/// The hilight green color
$green-hilight: #24BF7E;

/// The extra light green color
$green-extra-light: #9CE6C7;

/// The white color
$white: #ffffff;

/// The half white color
$white-half: #F9FAFC;

/// The light gray background color
$gray-light-bg: #F0F3F8;

/// The dark gray background color
$gray-dark-bg: #CCD6D9;

/// The middle gray background color
$gray-middle-bg: #CCD6D9;

/// The deep gray color
$gray-deep: #B3BAC0;

/// The dark gray color
$gray-dark: #687682;

/// The gray dividing line color
$gray-dividing: #D1D6D7;

/// The black color
$black: #000000;

$error: #FF0000;

$error-light: #FF9788 ;
