@use "src/styles/variables" as *;
@use "src/styles/mixins" as *;
@use "src/styles/placeholders" as *;

.empty-user-container {
  display: grid;
  @include grid-tiny-only([title] 10, 10);
  @include grid-small-up([title] 10, 10);
  @include grid-medium-up(1 [title] 10, 10);
  @include grid-big-up(1 [title] 8, 10);
  @include grid-large-up([title] 8 2, 10);
}

.empty-user-title {
  grid-column: title;
  @include indent-bottom(32);
}

.empty-user-button {
  grid-column: title;
}
