@use "./mixins" as *;
@use "./placeholders" as *;

.page-content {
  @include indent-top(48);
  @include indent-bottom(160);
}

.container {
  @extend %grid-container;
}
