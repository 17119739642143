@use "styles/reset";
@use "styles/base";
@use "styles/mixins";
@use "styles/placeholders";
@use "styles/functions";
@use "styles/components";
@use "styles/typography";
@use "styles/variables";

body {
  margin: 0;
  background-color: variables.$color-gray-light-bg;
}
