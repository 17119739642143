@use '../mixins' as *;
@use '../variables' as *;
@use '../placeholders' as *;

.input-range__slider {
  appearance: none;
  background: $color-green;
  border-radius: 100%;
  cursor: pointer;
  display: block;
  height: 1rem;
  width: 1rem;
  margin-top: -0.55rem;
  outline: none;
  position: absolute;
  top: 50%;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
}

.input-range__slider:active {
  transform: scale(1.3);
}

.input-range__slider:focus {
  box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
}

.input-range--disabled .input-range__slider {
  background: #cccccc;
  border: 1px solid #cccccc;
  box-shadow: none;
  transform: none;
}

.input-range__slider-container {
  transition: left 0.3s ease-out;

  &+.input-range__slider-container {

    .input-range__slider {
      background: $color-green;
      margin-left: -1rem;
    }
  }
}

.input-range__label {
  @extend %label-text;
  transform: translateZ(0);
  white-space: nowrap;
}

.input-range__label--min,
.input-range__label--max,
.input-range__label--value {
  display: none;
}

.input-range__track {
  background: $color-gray-dark-bg;
  cursor: pointer;
  display: block;
  height: 1px;
  position: relative;
  transition: left 0.3s ease-out, width 0.3s ease-out;
}

.input-range--disabled .input-range__track {
  background: $color-gray-dark;
}
.input-range__track--active {
  background: $color-green;
  height: 1px;
}
